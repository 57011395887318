import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import logo from "../images/cos_center_logo_small.png"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
    color: "black",
    height: "100px",
    display: "flex",
    alignItems: "center",
  },
  toolbar: {
    flex: "1 1 50%",
    width: "100%",
  },
  title: {
    fontWeight: "800",
    textTransform: "uppercase",
    fontSize: "20px",
    textDecoration: "rgb(38, 57, 71)",
    color: "#263947",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  cosLogo: {
    height: "40px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "5px",
      marginRight: "5px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textSize: "14px",
  },
}))

export default function NavBar() {
  const classes = useStyles()

  return (
    <AppBar position="fixed" className={classes.root} elevation={1}>
      <Toolbar className={classes.toolbar}>
        <a href="/" className={classes.logo}>
          <img
            src={logo}
            className={classes.cosLogo}
            alt="Center for Open Science"
          />
        </a>
        <Link href="/" underline="none">
          <Typography variant="subtitle1" noWrap className={classes.title}>
            TOP Factor
          </Typography>
        </Link>
        <Button
          className={classes.menuButton}
          color="inherit"
          component={Link}
          href="https://cos.io/top"
          target="_blank"
          underline="none"
          style={{ marginLeft: "auto" }}
        >
          About
        </Button>
        <Button
          className={classes.menuButton}
          color="inherit"
          component={Link}
          href="/journals"
          underline="none"
        >
          All Journals
        </Button>
        <Button
          className={classes.menuButton}
          color="inherit"
          component={Link}
          href="/summary"
          underline="none"
        >
          Summary Table
        </Button>
      </Toolbar>
    </AppBar>
  )
}
