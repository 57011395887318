import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Helmet } from "react-helmet"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"

import { useGetContent } from "../utils/requests"

import NavBar from "./NavBar"
import JournalSearchBar from "./JournalSearchBar"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    overflow: "hidden",
    position: "relative",
  },
  hero: {
    minHeight: "500px",
    backgroundColor: "rgb(218, 218, 218)",
  },
  content: {
    minHeight: "40vh",
    position: "relative",
    boxSizing: "border-box",
    flex: "1 1 50%",

    overflow: "hidden",
    paddingTop: "100px",
    paddingLeft: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: "bold",
    fontSize: "32px",
    [theme.breakpoints.up("md")]: {
      fontSize: "48px",
    },
  },
  subTitle: {
    fontSize: 18,
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  },
  aboutTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
    fontSize: "22px",
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
    },
  },
  aboutContent: {
    fontSize: "20px",
    marginBottom: theme.spacing(3),
  },
  aboutLink: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  searchContainer: {
    marginTop: "10px",
    [theme.breakpoints.up("sm")]: {
      minWidth: "420px",
      marginTop: "40px",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "720px",
      marginTop: "60px",
    },
  },
}))

const Hero = ({ title, subtitle, classes }) => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={5}
      className={classes.hero}
    >
      <Container className={classes.content}>
        <Box mb={2}>
          <Typography
            variant="h3"
            component="h1"
            align="center"
            className={classes.title}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          align="center"
          className={classes.subTitle}
        >
          {subtitle}
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          className={classes.searchContainer}
        >
          <JournalSearchBar />
        </Box>
        <Box mt={3}>
          <Typography
            align="center"
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            <Link href="/journals">Advanced Search by Standard, Discipline, or Publisher</Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default function Home() {
  const classes = useStyles()

  // Get home page content
  const defaultContent = {
    content: {
      title: "Transparency and Openness Promotion",
      subtitle:
        "Created by journals, funders, and societies to align scientific ideals with practices.",
      description:
        "Journal policies can be evaluated based on the degree to which they comply with the TOP Guidelines. This TOP Factor is a metric that reports the steps that a journal is taking to implement open science practices, practices that are based on the core principles of the scientific community. It is an alternative way to assess journal qualities, and is an improvement over traditional metrics that measure mean citation rates. The TOP Factor is transparent (see underlying data and the evaluation rubric) and will be responsive to community feedback.",
    },
  }
  const result = useGetContent(`/api/pages/home`, null)
  const {
    content: { title, subtitle, description },
  } = typeof result !== "undefined" ? defaultContent : result

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>TOP Factor - Transparency and Openness Promotion</title>
      </Helmet>
      <NavBar style={{ zIndex: "1000" }} />
      <Hero title={title} subtitle={subtitle} classes={classes} />
      <Box p={5}>
        <Container maxWidth="md">
          <Typography variant="h4" className={classes.aboutTitle}>
            About TOP Factor
          </Typography>
          <Typography className={classes.aboutContent}>
            {description}
          </Typography>
          <Box mb={3}>
            <Typography className={classes.aboutLink}>
              <Link href="https://www.cos.io/initiatives/top-guidelines">
                Read more about TOP Factor.
              </Link>
            </Typography>
          </Box>
          <Typography>
            For inquiries, please contact{" "}
            <Link style={{ fontWeight: "bold", color: "#3274B4" }} href="mailto:david@cos.io">David Mellor</Link> or email{" "}
            <Link style={{ fontWeight: "bold", color: "#3274B4" }} href="mailto:top@cos.io">top@cos.io</Link>
          </Typography>
        </Container>
      </Box>
    </Box>
  )
}
