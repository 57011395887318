import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

import Home from "./components/Home"
import JournalPage from "./components/JournalPage"
import Journals from "./components/Journals"
import SummaryPage from "./components/SummaryPage"
import FaqPage from "./components/FaqPage"

const theme = createTheme({
  palette: {
    primary: {
      main: "#337ab7"
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Open Sans, Roboto, sans-serif",
    allVariants: {
      color: "#263947"
    }
  }
})

export default function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/" component={Home} exact={true} />
            <Route path="/journals/:slug" component={JournalPage} />
            <Route path="/journals" component={Journals} />
            <Route path="/summary" component={SummaryPage} />
            <Route path="/frequently-asked-questions" component={FaqPage} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </React.Fragment>
  )
}
