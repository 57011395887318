import React, { useEffect } from "react"
import { useHistory } from "react-router-dom";
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"

import { makeStyles } from "@material-ui/core/styles"

import Autocomplete from "@material-ui/lab/Autocomplete"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import TextField from "@material-ui/core/TextField"

const useRootStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
    boxShadow: "0px 0px 15px rgba(21,165,235,0.15)",
    "& .MuiFormLabel-root": {
      left: theme.spacing(2),
      color: "#757575",
      fontSize: 20,
      "&.MuiInputLabel-shrink": {
        color: "#757575",
        top: "5px",
      },
    },
  },
}))

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    margin: 0,
    padding: 0,
    justifyContent: "space-between",
  },
  text: {
    padding: theme.spacing(0),
    boxShadow: "inset 0px 1px 2px rgba(0,0,0,0.2)",
    paddingLeft: theme.spacing(2),
  },
  inputEl: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  iconButton: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    padding: "0 15px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  iconEl: {
    fontSize: 32,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 0,
    marginBottom: 0,
  },
}))

const onInputChange = ({options, history}) => (event, value) => {
  const journal = options.find(journal => journal.name === value)
  if (journal) {
    history.push(`/journals/${journal.slug}`)
  }

}

export default function JournalSearchBar() {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0
  const history = useHistory()

  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    ;(async () => {
      const response = await fetch("/api/journals/search")
      const journals = await response.json()

      if (active) {
        setOptions(journals)
      }
    })()

    return () => {
      active = false
    }
  }, [loading])

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const rootClasses = useRootStyles()
  const classes = useStyles()

  return (
    <Autocomplete
      classes={rootClasses}
      fullWidth={true}
      autoHighlight={true}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      onInputChange={onInputChange({options, history})}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <Box className={classes.inputContainer}>
          <TextField
            {...params}
            className={classes.text}
            label="Search journals by title"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              className: classes.inputEl,
              disableUnderline: true,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
          <IconButton className={classes.iconButton} aria-label="submit journal search">
            <SearchIcon className={classes.iconEl} />
          </IconButton>
        </Box>
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.name, inputValue)
        const parts = parse(option.name, matches)

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        )
      }}
    />
  )
}
