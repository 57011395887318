import { useEffect, useState } from "react"

export const useGetContent = (path, defaultValue) => {
  const [content, setContent] = useState(defaultValue)

  useEffect(() => {
    async function getContent() {
      const result = await fetch(path)
      setContent(await result.json())
    }

    getContent()
  }, [path])

  return content
}
