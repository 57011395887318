import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Helmet } from "react-helmet"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"

import { useGetContent } from "../utils/requests"

import NavBar from "./NavBar"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    overflow: "hidden",
    position: "relative",
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: 14,
    textTransform: "uppercase",
  },
}))

export default function SummaryPage() {
  const classes = useStyles()
  const factors = useGetContent(`/api/factors/guidelines`, [])
  return (
    <Box className={classes.root}>
      <Helmet>
        <title>TOP Factor - Transparency and Openness Promotion</title>
      </Helmet>
      <NavBar style={{ zIndex: "1000" }} />
      <Box p={5}>
        <Container style={{ paddingTop: "100px" }}>
          <Box mb={5}>
            <Typography variant="h3" component="h1" align="center">
              TOP Guidelines Summary Table
            </Typography>
          </Box>
          <TableContainer
            component={Paper}
            elevation={0}
            variant="outlined"
            square
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {["Not Implemented", "Level I", "Level II", "Level III"].map(
                    (text) => {
                      return (
                        <TableCell key={text}>
                          <Typography className={classes.tableHeader}>
                            {text}
                          </Typography>
                        </TableCell>
                      )
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {factors &&
                  factors.length > 0 &&
                  factors
                    .sort(({ id: id1 }, { id: id2 }) => id1 - id2)
                    .filter(
                      ({ guidelines }) => Object.keys(guidelines).length > 0
                    )
                    .map((factor) => {
                      return (
                        <TableRow key={factor.id}>
                          <TableCell>
                            {" "}
                            <Typography style={{ fontWeight: "bold" }}>
                              {factor.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{factor.guidelines[0]}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{factor.guidelines[1]}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{factor.guidelines[2]}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{factor.guidelines[3]}</Typography>
                          </TableCell>
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
    </Box>
  )
}
