import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Helmet } from "react-helmet"
import sanitizeHtml from "sanitize-html"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import NavBar from "./NavBar"

// Allow only a super restricted set of tags and attributes
const defaultOptions = {
  allowedTags: ["strong", "a"],
  allowedAttributes: {
    a: ["href"],
  },
}

const sanitize = (dirty, options = {}) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
})

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    overflow: "hidden",
    position: "relative",
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: 14,
    textTransform: "uppercase",
  },
}))

const useFaqStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      color: theme.palette.primary.main
    }
  }}))

const faqContent = [
  [
    "What does TOP Factor measure?",
    "TOP Factor is a measure of openness and transparency for journals. TOP Factor reports the steps that a journal is taking to implement open science practices --practices that are based on the core principles of the scientific community.",
  ],
  [
    "Why is TOP Factor a useful metric?",
    "TOP Factor is a useful metric since it measures a journal’s level of openness and transparency unlike most traditional metrics that measure mean citation rates.",
  ],
  [
    "How was your scoring system developed?",
    "The TOP Guidelines were created by journals, funders, and societies to align scientific ideals with practices.",
  ],
  [
    "If a journal’s policies already encourage openness and transparency, why isn’t that always reflected in the journal’s TOP Factor score?",
    [
      "While encouraging openness and transparency are important steps towards increasing open science practices, <a href='https://faseb.onlinelibrary.wiley.com/doi/full/10.1096/fj.12-218164'>research</a> has shown that encouraging these practices alone is not as effective as requiring the practices. This is why level 1 of the TOP standards usually requires disclosure of practices, while level 2 requires adherence to practices.",
      "Another common reason a journal’s policies may not be scored as highly as expected, is that the journal’s policies require adherence to openness and transparency practices but only for certain types of studies or certain subsets of research. The TOP guidelines require adherence to openness and transparency practices across all empirical studies and all research outputs. For example, the “Data Transparency” standard applies to all data underlying reported findings. Likewise, the “Preregistration” standards apply to all inferential research, not just clinical trials, in which registration is already required.",
      "Finally, journal policy language can sometimes be unclear, with language that does not explicitly require adherence. For example, for data transparency, a journal might state that data deposition is “implied” or “expected.” Sometimes, a lower score can be an indication of unclear explicit requirements.",
    ],
  ],

  [
    "How can a journal increase its TOP Factor?",
    [
      "A journal can use our scoring rubric to identify and target which level is most appropriate. Editors can then use our <a href='https://osf.io/edtxm/'>recommended language</a> for journals as a guide when drafting a journal’s updated policies.",
      "Editors can also compare their journal’s policies with other journals in their field by searching on <a href='https://topfactor.org/'>https://topfactor.org/</a> and using high TOP Factor scored policies as examples for good practices.",
    ],
  ],
  [
    "Does a journal have to change all its policies or can it change just a few?",
    "It is not necessary to change all journal policies at once. Even changing one policy can make a difference. For example, if a journal does not currently require authors to declare a data availability statement, requiring one is an easy change that can have a big impact.",
  ],
  [
    "How can I suggest a journal to score?",
    "To suggest journals to be included, email <a href='mailto:top@cos.io'>top@cos</a>.",
  ],
]

export default function SummaryPage() {
  const classes = useStyles()
  const contentClasses = useFaqStyles()
  return (
    <Box className={classes.root}>
      <Helmet>
        <title>
          Frequently Asked Questions - Transparency and Openness Promotion
        </title>
      </Helmet>
      <NavBar style={{ zIndex: "1000" }} />
      <Box p={5}>
        <Container style={{ paddingTop: "100px" }} maxWidth="md">
          <Box mb={5}>
            <Typography variant="h3" component="h1" align="center">
              Frequently Asked Questions about TOP Factor
            </Typography>
          </Box>
          {faqContent.map(([question, answer], i) => (
            <Box my={3} key={i}>
            <Box mb={1}><Typography variant="h5" style={{fontWeight: "bold"}}>{question}</Typography></Box>
              {(typeof answer === "string" ? [answer] : answer).map((para, j) => (
                <Box mb={1} key={j}>
                  <Typography className={contentClasses.root} variant="body1" component="p" dangerouslySetInnerHTML={sanitize(para)} />
                </Box>
              ))}
            </Box>
          ))}
        </Container>
      </Box>
    </Box>
  )
}
