import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import queryString from "query-string"
import { createBrowserHistory } from "history"

import { makeStyles } from "@material-ui/core/styles"

import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Link from "@material-ui/core/Link"
import Paper from "@material-ui/core/Paper"
import SearchIcon from "@material-ui/icons/Search"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableFooter from "@material-ui/core/TableFooter"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import Skeleton from "@material-ui/lab/Skeleton"

import useDebounce from "../hooks/debounce"

import NavBar from "./NavBar"
import Facet from "./Facet"

const defaultParams = {
  page: 0,
  perPage: 10,
}

const history = createBrowserHistory()

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    overflow: "hidden",
    position: "relative",
  },
  content: {
    position: "relative",
    boxSizing: "border-box",
    flex: "1 1 50%",

    overflow: "hidden",
    paddingTop: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  searchBar: {
    backgroundColor: "#dadada",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    flexGrow: "1",
    textAlign: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  inputContainer: {
    display: "flex",
    margin: theme.spacing(3),
    padding: 0,
    justifyContent: "space-between",
    backgroundColor: "#fff",
    border: "1px solid #aaaaaa",
    borderRadius: "4px",
    boxShadow: "inset 0px 1px 2px rgba(0,0,0,0.2)",
    flexGrow: "2",
  },
  text: {
    padding: theme.spacing(0),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  iconButton: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    padding: "0 15px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  iconEl: {
    fontSize: 32,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 0,
    marginBottom: 0,
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: 14,
    textTransform: "uppercase",
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
    },
  },
}))

const useTextRootStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "55px",
    "& .MuiFormLabel-root": {
      top: "-6px",
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(-1),
      color: "#757575",
      width: "100%",
      "&.MuiInputLabel-shrink": {
        color: "#757575",
        top: "5px",
      },
    },
    "& .MuiInput-input": {
      padding: theme.spacing(0),
      width: "100%",
      height: "100%",
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
  },
}))

const useListRecords = (record) => {
  const [records, setRecords] = useState([])
  useEffect(() => {
    async function getRecords() {
      const result = await fetch(`/api/${record}`)
      setRecords(await result.json())
    }

    getRecords()
  }, [record])

  return records
}

const isObject = (val) =>
  Object.prototype.toString.call(val) === "[object Object]"

const useSearchJournals = (terms, { setIsLoading, queryParamsLoaded }) => {
  const { factor, disciplines, publishers, name, societies, page, perPage } =
    terms
  const [records, setRecords] = useState({ journals: [], meta: {} })

  const convertValue = (value, key = "id") => {
    if (typeof value === "object") return value[key]
    return value
  }

  useEffect(() => {
    if (queryParamsLoaded) {
      let params = new URLSearchParams()
      let searchQuery = new URLSearchParams()

      Object.entries(terms).forEach(([key, val]) => {
        if (Array.isArray(val) && val) {
          // Param is an array - disciplines, publishers, etc
          val.forEach((v) => {
            if (v) {
              params.append(`${key}[]`, convertValue(v))
              searchQuery.append(`${key}`, convertValue(v, "name"))
            }
          })
        } else if (isObject(val)) {
          // Param is an object - factor, mostly
          if (Object.entries(val).length !== 0)
            params.append(key, convertValue(val))

          // Skip if default factor
          if (key === "factor" && val["id"] !== 0)
            searchQuery.append(key, convertValue(val, "name"))
        } else if (val) {
          params.append(key, convertValue(val))

          if (
            (key === "perPage" && val !== defaultParams.perPage) ||
            key !== "perPage"
          )
            searchQuery.append(key, convertValue(val, "name"))
        }
      })

      async function getRecords() {
        setIsLoading(true)
        const result = await fetch(`/api/journals?${params.toString()}`)
        setRecords(await result.json())
        setIsLoading(false)
      }

      getRecords()
      try {
        history.push(`/journals?${searchQuery.toString()}`)
      } catch (e) {
        console.log(`Couldn't update history: ${e}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryParamsLoaded,
    factor.id,
    disciplines.length,
    publishers.length,
    societies.length,
    name,
    page,
    perPage,
  ])

  return records
}

const SearchBar = ({ classes, onSearchTermChange }) => {
  let loading = false
  const textRootClasses = useTextRootStyles()
  return (
    <Box className={classes.searchBar}>
      <Container>
        <Grid container>
          <Grid item sm={4} xs={12}>
            <Typography variant="h3" component="h1" className={classes.title}>
              All Journals
            </Typography>
          </Grid>
          <Grid item sm={8} xs={12}>
            <Box className={classes.inputContainer}>
              <TextField
                id="search-journals"
                classes={textRootClasses}
                label="Search journals by title"
                variant="standard"
                onChange={(event) => onSearchTermChange(event.target.value)}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <Box mr={2}>
                          <CircularProgress color="inherit" size={20} />
                        </Box>
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              />
              <IconButton className={classes.iconButton}  aria-label="submit journal search">
                <SearchIcon className={classes.iconEl} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const JournalResults = ({
  journals,
  factors,
  classes,
  isLoading,
  total,
  page,
  perPage,
  setPage,
  setPerPage,
}) => {
  const selectedFactor = factors.find((f) => f.selected)
  const showRank = selectedFactor && selectedFactor.id < 1
  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" square>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography className={classes.tableHeader}>Journal</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableHeader} align="right">
                {(selectedFactor && selectedFactor.name) || "Total TOP Factor"}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={journals && isLoading ? { opacity: "0.5" } : {}}>
          {isLoading &&
            (!journals || journals.length === 0) &&
            [...Array(10).keys()].map((i) => (
              <TableRow key={i}>
                <TableCell colSpan={2}>
                  <Skeleton />
                </TableCell>
                <TableCell style={{ width: "20%" }}>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ))}
          {!isLoading && journals && journals.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography style={{ fontSize: 20 }} align="center">
                  No results found
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {journals &&
            journals.length > 0 &&
            journals.map((journal, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography align="right" style={{ fontSize: 20 }}>
                    {showRank && page * perPage + index + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ fontSize: 20 }}>
                    <Link href={`/journals/${journal.slug}`}>
                      {journal.name}
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box px={2}>
                    <Typography
                      align="right"
                      style={{ fontSize: 36, fontWeight: "bold" }}
                    >
                      {selectedFactor && selectedFactor.id > 0
                        ? journal.factor_score || "0"
                        : journal.total}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        {journals && journals.length > 0 && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total || 0}
                page={page}
                rowsPerPage={perPage}
                onPageChange={(_event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setPage(0)
                  setPerPage(event.target.value)
                }}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}

export default function Journals({ location }) {
  const classes = useStyles()

  const [page, setPage] = useState(defaultParams.page)
  const [perPage, setPerPage] = useState(defaultParams.perPage)
  const [isLoading, setIsLoading] = useState(true)

  const allFactors = useListRecords("factors")
  const allDisciplines = useListRecords("disciplines")
  const allPublishers = useListRecords("publishers")
  const allSocieties = useListRecords("societies")

  const [facets, setFacets] = useState({
    factors: [],
    disciplines: [],
    publishers: [],
    societies: [],
  })

  // Set default facets based on returned results
  useEffect(() => {
    setFacets({
      factors: [
        { name: "Total TOP Factor", id: 0, selected: true },
        ...allFactors.map((f) => ({ ...f, selected: false })),
      ],
      disciplines: allDisciplines.map((f) => ({ ...f, selected: false })),
      publishers: allPublishers.map((f) => ({ ...f, selected: false })),
      societies: allSocieties.map((f) => ({ ...f, selected: false })),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allFactors.length,
    allDisciplines.length,
    allPublishers.length,
    allSocieties.length,
  ])

  const { factors, disciplines, publishers, societies } = facets
  const [searchTerm, setSearchTerm] = useState(null)
  const debouncedSearch = useDebounce(searchTerm, 300)

  const [queryParamsLoaded, setQueryParamsLoaded] = useState(false)
  const searchParams = queryString.parse(location.search)

  useEffect(() => {
    // All data is loaded
    if (
      [factors, disciplines, publishers, societies].every(
        (facet) => facet.length > 0
      ) &&
      !queryParamsLoaded
    ) {
      setPage(Number(searchParams["page"] || defaultParams.page))
      setPerPage(Number(searchParams["perPage"] || defaultParams.perPage))
      setFacets({
        factors: factors.map((f) => ({
          ...f,
          selected: searchParams["factor"]
            ? f.name === searchParams["factor"]
            : f.selected,
        })),
        publishers: publishers.map((f) => ({
          ...f,
          selected: (searchParams["publishers"] || []).indexOf(f.name) >= 0,
        })),
        disciplines: disciplines.map((f) => ({
          ...f,
          selected: (searchParams["disciplines"] || []).indexOf(f.name) >= 0,
        })),
        societies: societies.map((f) => ({
          ...f,
          selected: (searchParams["societies"] || []).indexOf(f.name) >= 0,
        })),
      })

      setQueryParamsLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, page, setPage, perPage, setPerPage, facets, setFacets])

  const {
    journals,
    meta: { total },
  } = useSearchJournals(
    {
      name: debouncedSearch,
      factor: factors.find((f) => f.selected) || {},
      disciplines: disciplines.filter((f) => f.selected),
      publishers: publishers.filter((f) => f.selected),
      societies: societies.filter((f) => f.selected),
      page,
      perPage,
    },
    { setIsLoading, queryParamsLoaded }
  )

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>Journals | TOP Factor</title>
      </Helmet>
      <Box className={classes.content}>
        <NavBar style={{ zIndex: "1000" }} />
        <SearchBar
          classes={classes}
          onSearchTermChange={(name) => {
            setSearchTerm(name)
            setPage(0)
          }}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      </Box>
      <Container>
        <Grid container>
          <Grid item sm={4} xs={12}>
            <Box m={2}>
              <Paper variant="outlined" square>
                <Facet
                  items={factors}
                  name="TOP Standard"
                  onFacetChange={(factors) => {
                    setFacets({ ...facets, factors })
                    setPage(0)
                  }}
                  inputType="radio"
                />
                <Facet
                  items={disciplines}
                  name="Discipline"
                  orderBy="name"
                  defaultExpanded={
                    (searchParams["disciplines"] || []).length > 0
                  }
                  onFacetChange={(disciplines) => {
                    setFacets({ ...facets, disciplines })
                    setPage(0)
                  }}
                />
                <Facet
                  items={publishers}
                  name="Publisher"
                  orderBy="name"
                  defaultExpanded={
                    (searchParams["publishers"] || []).length > 0
                  }
                  onFacetChange={(publishers) => {
                    setFacets({ ...facets, publishers })
                    setPage(0)
                  }}
                />
                <Facet
                  items={societies}
                  name="Society"
                  orderBy="name"
                  defaultExpanded={(searchParams["societies"] || []).length > 0}
                  onFacetChange={(societies) =>
                    setFacets({ ...facets, societies })
                  }
                />
              </Paper>
            </Box>
          </Grid>
          <Grid item sm={8}>
            <Box m={2}>
              <JournalResults
                journals={journals}
                factors={factors}
                classes={classes}
                isLoading={isLoading}
                total={total}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
