import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "@material-ui/core/Typography"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Checkbox from "@material-ui/core/Checkbox"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Radio from "@material-ui/core/Radio"

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0),
    backgroundColor: "transparent",
    width: "100%",
    "&::before": {
      backgroundColor: "transparent",
    },
  },
  list: {
    padding: theme.spacing(0),
  },
  listItem: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    width: "100%",
  },
}))

const useRootInputStyles = makeStyles((theme) => ({
  root: {
    color: "#263947",
  },
  checked: {
    color: "#263947 !important",
  },
}))

const attrOrder = (key, inputType) => (a, b) => {
  if (inputType === "checkbox") {
    // Selected, then alpha
    return b.selected - a.selected || a[key].localeCompare(b[key])
  } else {
    if (b[key] < a[key]) return 1
    if (b[key] > a[key]) return -1
    return 0
  }
}

export default function RadioFacet({
  name,
  items,
  link,
  inputType = "checkbox",
  orderBy = "id",
  defaultSelection = false,
  defaultExpanded = true,
  onFacetChange = () => {},
}) {
  const classes = useStyles()
  const inputClasses = useRootInputStyles()

  const toggleFacet = (facet) => () => {
    onFacetChange(
      [
        ...items
          .filter((f) => f.id !== facet.id)
          .map((f) => ({
            ...f,
            selected: inputType === "radio" ? false : f.selected,
          })),
        { ...facet, selected: inputType === "radio" ? true : !facet.selected },
      ].sort(attrOrder(orderBy))
    )
  }

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      elevation={0}
      className={classes.container}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{name}</Typography>
        {link}
      </AccordionSummary>
      <AccordionDetails className={classes.list}>
        <List style={{ width: "100%" }}>
          {items &&
            items.sort(attrOrder(orderBy, inputType)).map((item, i) => {
              const slug = name.replace(/\s+/g, '-').toLowerCase()
              const labelId = `${slug}-list-label-${i}`
              return <ListItem
                key={i}
                button
                disableRipple
                component="li"
                onClick={toggleFacet(item)}
                className={classes.listItem}
                role={undefined}
              >
                <ListItemIcon>
                  {inputType === "checkbox" && (
                    <Checkbox
                      disableRipple
                      checked={item.selected}
                      classes={inputClasses}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  )}
                  {inputType === "radio" && (
                    <Radio
                      checked={item.selected}
                      classes={inputClasses}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={item.name} id={labelId} />
              </ListItem>
})}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
