import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"

import { makeStyles } from "@material-ui/core/styles"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import IconButton from "@material-ui/core/IconButton"
import InputBase from "@material-ui/core/InputBase"
import Paper from "@material-ui/core/Paper"
import Popover from "@material-ui/core/Popover"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"

import ShareIcon from "@material-ui/icons/Share"
import FileCopyIcon from "@material-ui/icons/FileCopy"

import NavBar from "./NavBar"

import { copyTextToClipboard } from "../utils/copy"
import { useGetContent } from "../utils/requests"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    overflow: "hidden",
    position: "relative",
  },
  content: {
    position: "relative",
    boxSizing: "border-box",
    flex: "1 1 50%",

    overflow: "hidden",
    paddingTop: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  titleBar: {
    // backgroundColor: "#00b5ef", // COS Logo blue
    backgroundColor: "#3A6584", // Blue from previous version
    // backgroundColor: "#207b5d", // Green
    display: "flex",
    width: "100%",
    height: "100%",
  },
  title: {
    color: "#fff",
    fontSize: 32,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    fontWeight: "bold",
  },
  shareButton: {
    color: "#fff",
  },
  shareIcon: {
    fontSize: 32,
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: 14,
    textTransform: "uppercase",
  },
  linkButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  linkButtonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
    textAlign: "center",
  },
}))

const useShareStyles = makeStyles((theme) => ({
  iconButton: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    padding: "10px",
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

const ShareText = ({ text }) => {
  const classes = useShareStyles()
  const ref = useRef(null)
  const buttonRef = useRef(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  // const id = open ? "share-button" : undefined

  useEffect(() => {
    if (typeof ref !== "undefined" && typeof ref.current !== "undefined") {
      ref.current.select()
    }
  }, [ref])

  const handleInputClick = (event) => {
    const el = event.target
    el.select()
  }

  const handleClose = () => setAnchorEl(null)

  const handleCopyClick = (event) => {
    copyTextToClipboard(text)
    setAnchorEl(buttonRef.current)
    setTimeout(() => setAnchorEl(null), 1000)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ border: "1px" }}
      >
        <InputBase
          inputRef={ref}
          readOnly={true}
          defaultValue={text}
          onClick={handleInputClick}
          size="small"
          style={{ fontSize: 12, padding: "5px", minWidth: "200px" }}
        />
        <IconButton
          className={classes.iconButton}
          ref={buttonRef}
          title="Copy to clipboard"
          size="small"
          onClick={handleCopyClick}
        >
          <FileCopyIcon />
        </IconButton>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box p={1}>
          <Typography variant="button" style={{ fontSize: 12 }}>
            Copied to clipboard
          </Typography>
        </Box>
      </Popover>
    </Box>
  )
}

const TitleBar = ({ title, history, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? "share-button" : undefined
  const journalUrl = window.location.href

  const handleClick = (event) => {
    // TODO: If mobile, open web share
    try {
      const shareData = { title: title, url: journalUrl }
      navigator.share(shareData)
    } catch (e) {
      // console.log("Couldn't share: ", e)
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box className={classes.titleBar}>
      <Container>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h3" component="h1" className={classes.title}>
            {title}
          </Typography>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-describedby={id}
              aria-label="share page"
              onClick={handleClick}
              className={classes.shareButton}
            >
              <ShareIcon className={classes.shareIcon} />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <ShareText text={journalUrl} />
            </Popover>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const AboutHero = ({ about, classes }) => {
  return (
    <Paper elevation={0} variant="outlined" square>
      <Box p={4}>
        <Box mb={2}>
          <Typography
            variant="h3"
            component="h2"
            style={{
              fontSize: 14,
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Journal Description
          </Typography>
        </Box>
        <Typography style={{ fontSize: 16 }}>{about}</Typography>
      </Box>
    </Paper>
  )
}

const JournalTable = ({ total, journalFactors, classes }) => {
  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" square>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className={classes.tableHeader}>Guideline</Typography>
            </TableCell>
            <TableCell>
              <Typography
                className={classes.tableHeader}
                style={{ textAlign: "center" }}
              >
                Level
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableHeader}>Summary</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableHeader}>
                Justification
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                Total
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                style={{
                  fontSize: 36,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {total}
              </Typography>
            </TableCell>
            <TableCell colSpan={2}></TableCell>
          </TableRow>
          {journalFactors &&
            (journalFactors || [])
              .sort((a, b) => a.factor_id - b.factor_id)
              .map(({ score, justification, factor: { name, guidelines } }) => {
                return (
                  <TableRow key={name}>
                    <TableCell>
                      <Typography style={{ fontSize: 16 }}>{name}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        style={{
                          fontSize: 36,
                          fontWeight: "bold",
                        }}
                      >
                        {score || "0"}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ minWidth: "30%" }}>
                      <Typography style={{ fontSize: 16 }}>
                        {guidelines[`${score || 0}`]}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ minWidth: "30%" }}>
                      <Typography style={{ fontSize: 16 }}>
                        {justification}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const JournalSidebar = ({
  classes,
  disciplines,
  issn,
  eissn,
  updated,
  policyUrl,
  additionalInfo,
}) => {
  return (
    <Paper elevation={0} variant="outlined" square>
      <Box display="flex" flexDirection="column" p={3}>
        {disciplines && disciplines.length > 0 && (
          <Box mb={3}>
            <Box mb={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textTransform: "uppercase",
                }}
              >
                Disciplines
              </Typography>
            </Box>
            {disciplines.map(({ discipline: { name } }) => (
              <Box mb={1} key={name}>
                <Typography style={{ fontSize: 16 }}>{name}</Typography>
              </Box>
            ))}
          </Box>
        )}
        {issn && (
          <Box mb={3}>
            <Box mb={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textTransform: "uppercase",
                }}
              >
                ISSN Numbers
              </Typography>
            </Box>
            <Typography style={{ fontSize: 16 }}>{issn}</Typography>
          </Box>
        )}
        {eissn && (
          <Box mb={3}>
            <Box mb={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textTransform: "uppercase",
                }}
              >
                eISSN Numbers
              </Typography>
            </Box>
            <Typography style={{ fontSize: 16 }}>{eissn}</Typography>
          </Box>
        )}
        {updated && (
          <Box mb={3}>
            <Box mb={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textTransform: "uppercase",
                }}
              >
                Last Updated
              </Typography>
            </Box>
            <Typography style={{ fontSize: 16 }}>{updated}</Typography>
          </Box>
        )}
        {policyUrl && (
          <Button
            className={classes.linkButton}
            component={Link}
            href={policyUrl}
            target="_blank"
            underline="none"
          >
            <Typography className={classes.linkButtonText}>
              Journal Page
            </Typography>
          </Button>
        )}
        {additionalInfo && additionalInfo.length > 0 && (
          <Box mt={3} mb={2}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: 14,
                textTransform: "uppercase",
              }}
            >
              Additional Information
            </Typography>
            {additionalInfo.map(({ title, url }, i) => (
              <Box mt={1} key={i}>
                <Typography style={{ fontSize: 16 }}>
                  <Link href={url}>{title}</Link>
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default function JournalPage(...props) {
  const classes = useStyles()
  const { slug } = useParams()

  const {
    total,
    description,
    name,
    issn,
    eissn,
    updated,
    journal_links: additionalInfo,
    author_guideline_url: policyUrl,
    journal_factors: journalFactors,
    journal_disciplines: disciplines,
  } = useGetContent(`/api/journals/${slug}`, { content: {} })

  return (
    <Box className={classes.root}>
      {name && (
        <Helmet>
          <title>{name} | TOP Factor</title>
        </Helmet>
      )}
      <Box className={classes.content}>
        <NavBar style={{ zIndex: "1000" }} />
        <TitleBar title={name} classes={classes} />
        <Container>
          <Grid container>
            <Grid item md={10} sm={9} xs={12}>
              {description && (
                <Box m={2}>
                  <AboutHero about={description} classes={classes} />
                </Box>
              )}
              <Box m={2}>
                <JournalTable
                  total={total}
                  journalFactors={journalFactors}
                  classes={classes}
                />
              </Box>
            </Grid>
            <Grid item md={2} sm={3} xs={12}>
              <Box m={2}>
                <JournalSidebar
                  classes={classes}
                  disciplines={disciplines}
                  issn={issn}
                  eissn={eissn}
                  updated={updated}
                  policyUrl={policyUrl}
                  additionalInfo={additionalInfo}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mx={2}>
            <Divider />
          </Box>
          <Box m={2} display="flex" justifyContent="space-between">
            <Button
              component={Link}
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdU_iDswhIF8PLGEdrc6d2dRkRY6q2nwfKZCMixwCS5I_noJg/viewform"
            >
              <Typography color="primary" variant="body2" align="center">
                Submit an update or correction
              </Typography>
            </Button>
            <Button component={Link} href="/frequently-asked-questions">
              <Typography color="primary" variant="body2" align="center">
                Frequently Asked Questions
              </Typography>
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
